









import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import TourForm from '@/tour/TourForm.vue';
import EditorMode from '@/common/editor.mode';
export default Vue.extend({
  components: { TourForm, Scaffold },
  computed: {
    duplicate(): EditorMode {
      return EditorMode.duplicate;
    },
  },
});
